import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {UserGamesService} from 'src/app/services/user-games.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username?: string;
  password?: string;

  constructor(public globals: Globals, private router: Router, private userGamesService: UserGamesService,
              private cookieService: CookieService) { }

  ngOnInit(): void {
  }

  login(): void {
    console.log('Login in.');
    if (this.username == null || this.password == null){
      console.error('Credentials should be provided.');
      return;
    }
    this.userGamesService.login(this.username, this.password).subscribe(response => {

      if (response.id !== undefined || response.id !== null){
        // @ts-ignore
        this.cookieService.set('userId', response.id);
        this.globals.userId = `${response.id}`;
      }
      console.log(response);
      this.router.navigate(['home']);
    }, error => {
      alert('Log in failed.');
    });

  }

}
