<div class="center">
    <form class="form">
        <input type="text" id="searchName" name="searchName" [(ngModel)]="gameName" placeholder="Search game ..." />
        <input type="submit" class="btn btn-dark" (click)="search()" value="Search">
    </form>

    <table class="table center">
  <thead class="thead-dark">
    <tr>
      <th scope="col"></th>
      <th scope="col">Title</th>
      <th scope="col">Year</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let game of games">
        <td><img *ngIf="game.thumbnail!=='' && game.thumbnail!==undefined" src="{{ game.thumbnail }}" /></td>
        <th scope="row">{{ game.name }}</th>
        <td>{{ game.year }}</td>
        <td>
            <input *ngIf="game.thumbnail==='' || game.thumbnail===undefined" type="submit" class="btn btn-success" (click)="addgame(game.id)" value="Add to the system" />
            <a *ngIf="game.thumbnail!=='' && game.thumbnail!==undefined" class="btn btn-primary" [routerLink]="['/game', game.id]">Details</a>
        </td>
    </tr>
      </tbody>
</table>

</div>
