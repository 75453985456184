import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {Globals} from 'src/app/globals';
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(globals: Globals, router: Router, private cookieService: CookieService) {
    const cookieId = cookieService.get('userId');
    console.log(cookieId);
    if (cookieId === undefined || cookieId === null || cookieId.length === 0) {
      router.navigate(['login']);
    } else {
      globals.userId = cookieId;
    }
  }
}
