import { Component, OnInit } from '@angular/core';
import { UserGamesService } from 'src/app/services/user-games.service';
import { GameDB } from 'src/app/models/GameDB';

@Component({
  selector: 'app-my-games',
  templateUrl: './my-games.component.html',
  styleUrls: ['./my-games.component.css']
})
export class MyGamesComponent implements OnInit {

  games?: GameDB[] = [];

  constructor(private userGamesService: UserGamesService) { }

  ngOnInit(): void {
    this.userGamesService.getMyGames().subscribe(results => {
      this.games = results.results;
    });
  }

  filterUsers(playerCount: number): void {
    this.userGamesService.getMyGames(playerCount).subscribe(results => {
      this.games = results.results;
    });
  }
}
