import { Component, OnInit } from '@angular/core';
import { UserGamesService } from 'src/app/services/user-games.service';
import { GameDB } from 'src/app/models/GameDB';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  games?: GameDB[] = [];

  constructor(private userGamesService: UserGamesService) { }

  ngOnInit(): void {
    this.userGamesService.getGames().subscribe(results => {
      this.games = results.results;
    });
  }

  filterUsers(playerCount: number): void {
    this.userGamesService.getGames(playerCount).subscribe(results => {
      this.games = results.results;
    });
  }

}
