<div class="center">
    <div class="row">
        <div class="col-sm">
            <h1>{{ game.name }}</h1>
            <hr />
        </div>
    </div>

    <div class="row">
        <div class="col-sm">
            <img src="{{ game.thumbnail }}" />
        </div>
        <div class="col-sm">
            <b>Published:</b> {{ game.yearpublished }} <br />
            <b>Min age:</b> {{ game.minage }} <br />
            <b>Players:</b> {{ game.minplayers }} - {{ game.maxplayers}}<br />
            <b>Playtime:</b> {{ game.playingtime }}<br />
            <b>Min:</b> {{ game.minplaytime }}<br />
            <b>Max:</b> {{ game.maxplaytime }}<br />
        </div>
        <div class="col-sm">
            <b>Owners:</b>
            <div *ngFor="let owner of game.owners">
                {{ owner.name }}
            </div>
            <input *ngIf="!owned" type="submit" class="btn btn-success" (click)="addToCollection(game.id)" value="Add to collection" />
            <input *ngIf="owned" type="submit" class="btn btn-danger" (click)="removeFromCollection(game.id)" value="Remove from collection" />
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <hr />
            <b>Description:</b> <br />
            {{ game.description }}
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <hr />
            <h3>Tutorial</h3>
            <div class="center-item">
                <youtube-player videoId="{{ game.tutorial }}" *ngIf="game.tutorial!==''"></youtube-player>
            </div>
            <div *ngIf="game.tutorial===''">
                <form class="form">
                    <input type="text" id="tutorialStr" name="tutorialStr" [(ngModel)]="tutorialStr" placeholder="Add a tutorial ..." />
                    <input type="submit" class="btn btn-primary" (click)="addTutorial()" value="Add tutorial">
                </form>
            </div>
            <hr />
        </div>
    </div>
</div>
