<nav class="navbar navbar-dark bg-dark navbar-expand-lg">
  <a class="navbar-brand" href="#">GameManager</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
            <li class="nav-item active">
                <a class="nav-link" routerLink="home">Home</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="my-games">My games</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="search">Search</a>
            </li>
        </ul>
 </div>
</nav>
