import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Game} from 'src/app/models/Game';
import { UserGamesService } from 'src/app/services/user-games.service';
import {Observable} from 'rxjs';
import {Globals} from 'src/app/globals';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {

  game: Game = {};
  gameId?: string | null;
  owned = false;
  tutorialStr?: string;

  constructor(private route: ActivatedRoute, private userGamesService: UserGamesService, private globals: Globals) { }

  ngOnInit(): void {
    this.gameId = this.route.snapshot.paramMap.get('gameId');
    this.gameId = this.gameId ? this.gameId : '';
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
    this.loadGame();
  }

  loadGame(): void {
    if (this.gameId == null) {
      console.error(`Game id: ${this.gameId}`);
      return;
    }
    this.userGamesService.getGameDetails(this.gameId).subscribe(response => {
      const urlParams = new URLSearchParams(response.tutorial?.substr(response.tutorial?.indexOf('?')));
      response.tutorial = urlParams.get('v') ? urlParams.get('v') : '';
      this.game = response;
      if (response.owners === undefined) {
        response.owners = [];
      }
      response.owners.forEach((owner) => {
        if (`${owner.id}` === this.globals.userId) {
          this.owned = true;
        }
      });
    });
  }

  addToCollection(gameId?: number | null): void {
    if (this.gameId == null) {
      console.error(`Game id: ${this.gameId}`);
      return;
    }
    this.userGamesService.addGameToColletion(gameId).subscribe(response => {
      this.loadGame();
      // TODO: test if the load can be changed
    });
  }

  removeFromCollection(gameId?: number | null): void {
    if (this.gameId == null) {
      console.error(`Game id: ${this.gameId}`);
      return;
    }
    console.log(`Remove from collection ${gameId}. Pending to implement`);
    this.userGamesService.deleteGameFromColletion(gameId).subscribe(response => {
      this.loadGame();
      // TODO: test if the load can be changed
    });
  }

  addTutorial(): void {
    if (this.gameId == null) {
      console.error(`Game id: ${this.gameId}`);
      return;
    }
    if (this.tutorialStr == null) {
      console.error(`Tutorial: ${this.tutorialStr}`);
      return;
    }
    this.userGamesService.addTutorial(this.gameId, this.tutorialStr).subscribe(response => {
      this.loadGame();
    });
  }
}
