<div class="center">
    <form>
      <div class="form-group">
        <label for="usernameInput">Username</label>
        <input type="email" class="form-control" id="usernameInput" name="usernameInput" placeholder="Enter Username" [(ngModel)]="username">
      </div>
      <div class="form-group">
        <label for="passwordInput">Password</label>
        <input type="password" class="form-control" id="passwordInput" name="passwordInput" placeholder="Password" [(ngModel)]="password">
      </div>
      <button type="submit" class="btn btn-primary" (click)="login()">Login</button>
    </form>
</div>


