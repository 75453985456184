import { Component, OnInit } from '@angular/core';
import { UserGamesService } from 'src/app/services/user-games.service';
import { SearchResult } from 'src/app/models/SearchResult';
import { SearchGame } from 'src/app/models/SearchGame';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  gameName?: string;

  games?: SearchGame[] = [];

  constructor(private userGamesService: UserGamesService) { }

  ngOnInit(): void {}

  search(): void {
    this.userGamesService.findGames(this.gameName).subscribe(results => {
      this.games = results.results;
    });
  }

  addgame(gameId?: number): void {
    this.userGamesService.addGameToSystem(gameId).subscribe(response => {
      this.userGamesService.findGames(this.gameName).subscribe(results => {
        this.games = results.results;
      });
    });
  }

}
