import { Injectable } from '@angular/core';
import { Globals } from 'src/app/globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResultsGameDB } from 'src/app/models/ResultsGameDB';
import { SearchResult } from 'src/app/models/SearchResult';
import { Game } from 'src/app/models/Game';
import { User } from 'src/app/models/User';

@Injectable({
    providedIn: 'root'
})
export class UserGamesService {

    httpUrl = 'http://games.quirosreyes.com/api/';

    httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(private globals: Globals, private http: HttpClient) {
    }


    getGames(playerCount?: number): Observable<ResultsGameDB> {
        let playerString = '';
        if (playerCount !== undefined) {
            playerString = `&players=${playerCount}`;
        }
        return this.http.get<ResultsGameDB>(`${this.httpUrl}users/${this.globals.userId}/games?friends=true${playerString}`);
    }

    getMyGames(playerCount?: number): Observable<ResultsGameDB> {
        let playerString = '';
        if (playerCount !== undefined) {
            playerString = `&players=${playerCount}`;
        }
        return this.http.get<ResultsGameDB>(`${this.httpUrl}users/${this.globals.userId}/games?friends=false${playerString}`);
    }

    findGames(gameName?: string): Observable<ResultsGameDB> {
        return this.http.get<SearchResult>(`${this.httpUrl}games/search/${gameName}`);
    }

    addGameToSystem(gameId?: number): Observable<any> {
        const httpOptions = { headers : this.httpHeaders };
        return this.http.post<any>(`${this.httpUrl}games/${gameId}`, {}, httpOptions);
    }

    getGameDetails(gameId?: string): Observable<Game> {
        return this.http.get<Game>(`${this.httpUrl}users/${this.globals.userId}/games/${gameId}`);
    }

    addGameToColletion(gameId?: number | null | undefined): Observable<any> {
        const httpOptions = { headers : this.httpHeaders };
        return this.http.post<any>(`${this.httpUrl}users/${this.globals.userId}/games/${gameId}`, {}, httpOptions);
    }

    deleteGameFromColletion(gameId?: number | null | undefined): Observable<any> {
        const httpOptions = { headers : this.httpHeaders };
        return this.http.delete<any>(`${this.httpUrl}users/${this.globals.userId}/games/${gameId}`, {});
    }

    addTutorial(gameId: string, tutorial: string): Observable<any> {
        const httpOptions = { headers : this.httpHeaders };
        const body = {tutorial};
        return this.http.patch<any>(`${this.httpUrl}games/${gameId}`, body, httpOptions);
    }

    login(username: string, password: string): Observable<User> {
        const httpOptions = { headers : this.httpHeaders };
        const body = {username, password};
        return this.http.post<any>(`${this.httpUrl}login`, body, httpOptions);
    }
}
