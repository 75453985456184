<div class="center">
    <div class="buttons-div">
        Players:
        <input type="submit" class="btn btn-dark" value="1" (click)="filterUsers(1)" />
        <input type="submit" class="btn btn-dark" value="2" (click)="filterUsers(2)" />
        <input type="submit" class="btn btn-dark" value="3" (click)="filterUsers(3)" />
        <input type="submit" class="btn btn-dark" value="4" (click)="filterUsers(4)" />
        <input type="submit" class="btn btn-dark" value="5" (click)="filterUsers(5)" />
        <input type="submit" class="btn btn-dark" value="6+" (click)="filterUsers(6)" />
    </div>
    <div class="count">Count: {{ games? games.length : 0 }}</div>
<table class="table">
  <thead class="thead-dark">
    <tr>
      <th scope="col"></th>
      <th scope="col">Title</th>
      <th scope="col">Players</th>
      <th scope="col">Time</th>
      <th scope="col">Owners</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let game of games">
        <td class="center-item"><img src="{{ game.thumbnail }}" /></td>
        <th scope="row">{{ game.title }}</th>
        <td>{{ game.minPlayers }} - {{ game.maxPlayers }}</td>
        <td>{{ game.minTime }} - {{ game.maxTime }}</td>
        <td><div *ngFor=" let owner of game.owners">{{owner.name}}</div></td>
        <td><a class="btn btn-success" [routerLink]="['/game', game.id]">Details</a></td>
    </tr>
      </tbody>
</table>
</div>
