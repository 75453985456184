import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from 'src/app/components/login/login.component';
import {HomeComponent} from 'src/app/components/home/home.component';
import {MyGamesComponent} from 'src/app/components/my-games/my-games.component';
import {SearchComponent} from 'src/app/components/search/search.component';
import {GameComponent} from 'src/app/components/game/game.component';


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'my-games', component: MyGamesComponent},
  {path: 'search', component: SearchComponent},
  {path: 'game/:gameId', component: GameComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
